import { CLIENT_ID } from '../config/Config'
import React, { useState, useEffect, useRef } from "react" ;
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './PayPageStyle.css';
import { useNavigate } from "react-router-dom";



const PayPage = ({mailAdress, genre, songtext, setOrderNo}) => {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const navigate = useNavigate();
    const endOfSide = useRef(null);

    // Weiterer Code hier
    //console.log('Tag is now a string:', tag);

    const changeAccountState = async () => {
        const orderNo = "0815_Phanti"
        SendMail(mailAdress,genre,songtext,orderNo)
        setOrderNo(orderNo)
        navigate("/ThanksOrder")
    };

    

    // Funktion zum Scrollen ans Ende der Seite
    const scrollToBottom = () => {
        endOfSide.current?.lastElementChild?.scrollIntoView()
    };

    const handleCheckboxChange = (event) => {
        setShow(event.target.checked);
        scrollToBottom();
    };

    const SendMail = async (mailAdress, genre, songtext, orderID) => {
        // Daten für den Request
        const data = {
            mailAdress: mailAdress,
            genre: genre,
            songtext: songtext,
            orderID: orderID
        };
    
        console.log("Zu sendende Daten:", data);
    
        const url = "https://songs.phantiwood.de/Server/SendMailProxy.php";
    
        try {
            // Anfrage mit fetch senden
            const response = await fetch(url, {
                method: "POST", // POST-Request
                headers: {
                    "Content-Type": "application/json", // JSON-Daten
                },
                body: JSON.stringify(data), // Daten als JSON-String
            });
    
            if (!response.ok) {
                throw new Error(`HTTP-Fehler! Status: ${response.status}`);
            }
    
            const responseData = await response.json(); // Antwortdaten verarbeiten
            console.log("Serverantwort:", responseData);
    
            return responseData; // Rückgabe der Antwort für weitere Verarbeitung
        } catch (error) {
            console.error("Fehler beim Senden der Anfrage:", error);
            throw error; // Fehler weitergeben, falls notwendig
        }
    };

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "PhantiSong",
                    amount: {
                        currency_code: "EUR",
                        value: 49.00,
                    },
                },
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                SendMail(mailAdress,genre,songtext, orderID)
                setOrderNo(orderID)
                navigate("/ThanksOrder")
                return orderID;
            });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
            //console.log('Tag is now a string:', tag);
            //changeAccountState(tag)
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };

    useEffect(() => {
        if (success) {
            //alert("Payment successful!!");
            console.log('Order successful . Your order id is--', orderID);
        }
    },[success]);

    //<button onClick={changeAccountState}> Test Mail</button>

    return (
        <div className='payPage_container'>
            <div className="payPage_titleContainer">
                <h2>PhantiSong - Dein einzigartiger Song!</h2>
            </div>
            <PayPalScriptProvider options={{ "client-id": CLIENT_ID, "currency": 'EUR' }}>
                <div className="payPage_product-text">
                    <h3>Warum einen PhantiSong bestellen?</h3>
                    <p>
                        Entdecke die Magie eines einzigartigen, von KI generierten Songs, der speziell für dich erstellt wird. 
                        Egal, ob als Geschenk, für deinen persönlichen Moment oder als Highlight auf einer Veranstaltung – dein PhantiSong ist individuell und unvergleichlich.
                    </p>

                    <h3>Ein unvergleichliches Angebot</h3>
                    <p>
                        Nur für kurze Zeit: Statt <span className="payPage_strike">119,00 €</span> erhältst du deinen Song für nur <strong>49,00 €</strong>!
                    </p>

                    <h3>Was bekommst du?</h3>
                    <ul className="payPage_feature-list">
                        <li><strong>Einzigartiger Song:</strong> Speziell für dich komponiert und produziert.</li>
                        <li><strong>Individuelle Note:</strong> Gib uns deine Wünsche, und wir kreieren deinen Sound.</li>
                        <li><strong>Hochwertige Audioqualität:</strong> Perfekt für den direkten Einsatz.</li>
                    </ul>
                </div>

                <div>
                    <p className="payPage_highlighted-price">Nur 49,00 €</p>
                    <br />
                </div>

                <div className="payPage_checkbox-container">
                    <input
                        type="checkbox"
                        id="accept-agbs"
                        checked={show}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="accept-agbs" className="payPage_checkbox-label">
                        Ich akzeptiere die <a href="https://www.phantiwood.de/content/7-widerruf" target="_blank" rel="noopener noreferrer">AGBs</a>
                    </label>

                    
                </div>

                <br />
                {show ? (
                    <PayPalButtons
                        ref={endOfSide}
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                    />
                ) : null}
            </PayPalScriptProvider>
        </div>
    );
};

export default PayPage;